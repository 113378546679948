import React from "react"
import {
  Wrapper,
  RowWrapper,
  LaunchContent,
  TitleWrapper,
  SubtitleWrapper,
  StoresWrapper,
  BetweenStoresButtons,
  StoreImage,
  ImageWrapper,
  ImageContent,
  AppleStoreMobile,
  GooglePlayMobile,
} from "./LaunchScreen.module.css"
import AppleStore from "./../../../res/apple_store.png"
import GooglePlay from "./../../../res/google_play.png"
import LaunchScreenMockup from "./../../../res/mockups/mockup_map.png"

const LaunchScreen = () => {
  return (
    <div className={Wrapper}>
      <div className={RowWrapper}>
        <div className={LaunchContent}>
          <h1 className={TitleWrapper}>
            Les bons plans marseillais et aixois pour les 15-30 ans
          </h1>
          <p className={SubtitleWrapper}>
            WYNIT est une application qui permet aux jeunes de 15 à 25 ans de
            profiter des bons plans et des offres du réseau de partenaires de
            WYNIT.
          </p>
          <div className={StoresWrapper}>
            <div className={AppleStoreMobile}>
              <a href="https://apps.apple.com/fr/app/wynit/id1576761700">
                <img
                  alt="Apple store"
                  className={StoreImage}
                  src={AppleStore}
                />
              </a>
            </div>
            <div className={BetweenStoresButtons}></div>
            <div className={GooglePlayMobile}>
              <a href="https://play.google.com/store/apps/details?id=com.wynit.app">
                <img
                  alt="Google play"
                  className={StoreImage}
                  src={GooglePlay}
                />
              </a>
            </div>
          </div>
        </div>
        <div className={ImageWrapper}>
          <img
            alt="Mockup map"
            className={ImageContent}
            src={LaunchScreenMockup}
          />
        </div>
      </div>
    </div>
  )
}

export default LaunchScreen
