import React from "react"
import {
  Wrapper,
  RowWrapper,
  ImageWrapper,
  DescriptionWrapper,
  ButtonWrapper,
} from "./SpecialOffer.module.css"

import Button from "./../../../components/Button"

import disneylandParis from "./DisneylandParis-30eAnniversaire-mickey.jpeg"

const SpecialOffer = ({ user }) => {
  const handleSeeWebsite = () => {
    if (user) {
      window.location.href =
        "https://www.booktickets.disneylandparis.com/tnsa64/live/shop/1/SUB1FRCE/index.php?language=fr&country=FR"
    } else {
      window.location.href = "/se-connecter"
    }
  }

  return (
    <div className={Wrapper} id="specialOffer">
      <h2>Offres Disneyland Paris</h2>
      <p>
        Profite d'entrées pour le parc <span>à partir de 52€</span> !
      </p>
      <div className={RowWrapper}>
        <div className={ImageWrapper}>
          <img src={disneylandParis} />
        </div>
        <div className={DescriptionWrapper}>
          <p>
            Viens découvrir ou Re découvrir la magie de Disneyland Paris, à
            l’occasion du 30eme anniversaire du parc ! Un grand nombre de
            nouveautés et de surprises t’attendent !
          </p>
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => handleSeeWebsite()}>
              Voir le site
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecialOffer
