import React, { useState } from "react";
import {
  Wrapper,
  DescriptionWrapper,
  FormWrapper,
  RowWrapper,
  InputWrapper,
  ButtonWrapper,
  Spacer,
} from "./NousContacter.module.css";
import {
  PaddingTop30,
  PaddingBottom10,
} from "./../../../css/Padding.module.css";
import Select from "./../../../components/Select";
import Button from "./../../../components/Button";
import { classNames } from "./../../../helpers/classNames";
import { OrangeTitle } from "./../../../css/Titre.module.css";
import * as emailjs from "emailjs-com";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NousContacter = ({}) => {
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false);
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");

  const objects = [
    "Demande d'informations",
    "Devenir un wynner",
    "Devenir partenaire",
    "Autres",
  ];

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false);
  };

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false);
  };

  const handleSendContactFormButton = () => {
    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      phoneNumber === "" ||
      object.value === "" ||
      object.value === undefined ||
      message === ""
    ) {
      setErrorSnackbarIsOpened(true);
      return;
    }

    var data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phoneNumber: phoneNumber,
      object: object.value,
      message: message,
    };

    emailjs
      .send(
        "service_kb92jtv",
        "template_432pvv2",
        data,
        "user_UnRgFjW8xgz1FyCm82I2z"
      )
      .then(
        function (response) {
          setSuccessSnackbarIsOpened(true);
          //console.log(response.status, response.text);
        },
        function (err) {
          setErrorSnackbarIsOpened(true);
          //console.log(err);
        }
      );
  };

  return (
    <div className={Wrapper}>
      <h2 className={OrangeTitle}>Nous contacter</h2>
      <p className={DescriptionWrapper}>
        Une question ? Dis-nous tout, on se fera un plaisir de te répondre.
      </p>
      <form className={FormWrapper}>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="firstName">Prénom</label>
            </div>
            <input
              id="firstName"
              type="text"
              placeholder="ex: Hugo"
              name="Prenom"
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="lastName">Nom de famille</label>
            </div>
            <input
              id="lastName"
              name="Nom"
              type="text"
              placeholder="ex: Gualtieri"
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
        </div>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="phoneNumber">Téléphone</label>
            </div>
            <input
              id="phoneNumber"
              name="telephone"
              type="tel"
              placeholder="ex: 06.06.06.06.06"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="email">Email</label>
            </div>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="ex: name@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="object">Objet</label>
          </div>
          <Select
            id="object"
            name="subject"
            placeholder="Sélectionnez une objet"
            options={objects.map((data) => ({
              value: data,
              label: data,
            }))}
            onChange={(e) => setObject(e)}
            value={object}
          />
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="message">Message</label>
          </div>
          <textarea
            id="message"
            name="message"
            type="message"
            placeholder="Écrivez votre message ici..."
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className={classNames([PaddingTop30, RowWrapper])}>
          <div className={Spacer}></div>
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => handleSendContactFormButton()}>
              Envoyer
            </Button>
          </div>
        </div>
      </form>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien envoyé ton mail !
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Une erreur est survenue lors de l'envoie de ton mail. Vérifie
          les informations que tu as rentré.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NousContacter;
