import React, { useState } from "react"
import {
  Wrapper,
  FAQContent,
  HelpListWrapper,
  HelpWrapper,
  HelpWrapperDeployed,
  TitleAndIconWrapper,
  OrangeText,
  WhiteText,
  PlusWrapper,
  HelpContent,
  Spacer,
} from "./FAQ.module.css"
import Plus from "./../../../res/icons/white_plus.png"
import Moins from "./../../../res/icons/colored_minus.png"
import { WhiteTitle } from "./../../../css/Titre.module.css"

const HelpComponent = ({ title, description }) => {
  const [isShowed, setIsShowed] = useState(false)

  const toggleHelp = () => {
    if (isShowed) {
      setIsShowed(false)
    } else {
      setIsShowed(true)
    }
  }

  return (
    <div className={isShowed ? HelpWrapperDeployed : HelpWrapper}>
      <div className={TitleAndIconWrapper}>
        <p
          className={isShowed ? OrangeText : WhiteText}
          style={{ width: "50vw" }}
        >
          {title}
        </p>
        <div className={Spacer}></div>
        <button
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={toggleHelp}
        >
          <img
            className={PlusWrapper}
            src={isShowed ? Moins : Plus}
            alt={isShowed ? "moins" : "plus"}
            width="30px"
            height="30px"
          />
        </button>
      </div>
      {isShowed ? <p className={HelpContent}>{description}</p> : null}
    </div>
  )
}

const FoireAuxQuestions = () => {
  return (
    <div className={Wrapper}>
      <div className={FAQContent}>
        <h2 className={WhiteTitle}>FAQ</h2>
        {/*<p style={{ color: "#FFFFFF" }}>SearchBar</p>*/}
        <div className={HelpListWrapper}>
          <HelpComponent
            title="Être un wynner, ça consiste en quoi ?"
            description="Devenir un Wynner, c'est gagner à tous les coups ! Sortir et profiter de sa jeunesse tout en faisant de belles économies, qui dit mieux ?"
          />
          <HelpComponent
            title="Quelles sont les offres proposées ?"
            description="Sur Wynit, tu trouveras 2 types d'offres: Les offres permanentes sont valables tout au long de ton abonnement à raison d'une fois par jour maximum. Les offres de bienvenue sont des maxi cadeaux disponibles lors de ta première visite dans chaque établissement."
          />
          <HelpComponent
            title="Comment profiter d'une offre ?"
            description="Pour débloquer une offre, rien de plus simple ! Choisis le partenaire que tu souhaites, sélectionne l'une de ses offres et clique sur le bouton 'Utiliser l'offre' à présent tu disposes de 15 minutes top chrono pour présenter ton ticket à la caisse de l'établissement !"
          />
        </div>
      </div>
    </div>
  )
}

export default FoireAuxQuestions
