import React, { useState, useEffect } from "react"

import "../css/index.css"
import Layout from "./../components/Layout"
import Landing from "./../nav/Landing"
import { Helmet } from "react-helmet"
import { getUser } from "./../auth"
import firebase from "gatsby-plugin-firebase"
import Thumbnail from "../components/ThumbNail"

import { getOfferFormatted } from "./../utils/formating"

const IndexPage = ({ data }) => {
  const [user, setUser] = useState(undefined)
  const [offers, setOffers] = useState([])

  //const { title, description } = data.site.siteMetadata

  useEffect(() => {
    getUser(setUser)
    firebase
      .firestore()
      .collection("offers")
      .get()
      .then(docs => {
        let idx = 0
        let newOffers = []

        while (idx < docs.docs.length) {
          newOffers.push(getOfferFormatted(docs.docs[idx]))
          idx++
        }
        setOffers(newOffers)
      })
      .catch(error => {
        //console.log("Error getting document:", error)
      })
  }, [])

  return (
    <Layout user={user}>
      <Thumbnail />
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Accueil</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Sur cette page,
          tu trouveras toutes les informations à propos de WYNIT."
        />
        <meta
          name="keywords"
          content="bons plans,marseille,réductions,jeunes,wynit,informations"
        />
      </Helmet>
      <Landing user={user} offers={offers} />
    </Layout>
  )
}

export default IndexPage
