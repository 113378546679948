// extracted by mini-css-extract-plugin
export var ArgumentDescription = "Arguments-module--ArgumentDescription--Axpfg";
export var ArgumentTextsWrapper = "Arguments-module--ArgumentTextsWrapper--3Y9vH";
export var ArgumentTitle = "Arguments-module--ArgumentTitle--4dIsE";
export var ArgumentWrapper = "Arguments-module--ArgumentWrapper--MZgq4";
export var ArgumentsWrapper = "Arguments-module--ArgumentsWrapper--ox-XX";
export var DescriptionWrapper = "Arguments-module--DescriptionWrapper--pEChg";
export var ImageContent = "Arguments-module--ImageContent--WwqXG";
export var ImageWrapper = "Arguments-module--ImageWrapper--S2709";
export var MobileWrapper = "Arguments-module--MobileWrapper--9QdIv";
export var RowWrapper = "Arguments-module--RowWrapper--ZMPSe";
export var Wrapper = "Arguments-module--Wrapper---xtZd";