import React from "react"
import {
  Wrapper,
  DescriptionWrapper,
  ArgumentsWrapper,
  ArgumentWrapper,
  RowWrapper,
  ImageWrapper,
  ImageContent,
  MobileWrapper,
  ArgumentTextsWrapper,
  ArgumentTitle,
  ArgumentDescription,
} from "./Arguments.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import { PaddingTop20 } from "./../../../css/Padding.module.css"
import HomeMockup from "./../../../res/mockups/mockup_home.png"
import ProfileMockup from "./../../../res/mockups/mockup_profile.png"
import MapMockup from "./../../../res/mockups/mockup_map.png"

const Arguments = () => {
  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>WYNIT, qu'est-ce que c'est ?</h1>
      <div className={DescriptionWrapper}>
        Une solution 100% dématérialisée pour obtenir des remises tout au long
        de la journée ? Que peut-on rêver de mieux ? Ah oui ! Que les offres
        soit déjà négociées et géo-localisées ? Mais si nous étions vraiment les
        meilleurs, tous ces avantages seraient directement accessibles
        gratuitement la première année ? Et bien... WYNIT, c'est tout ça, et en
        mieux !
      </div>
      <div className={ArgumentsWrapper}>
        <div className={ArgumentWrapper}>
          <div className={RowWrapper} style={{ flexWrap: "wrap-reverse" }}>
            <div className={ImageWrapper}>
              <img
                alt="Mockup carte"
                className={ImageContent}
                src={MapMockup}
              />
            </div>
            <div className={MobileWrapper}></div>
            <div className={ArgumentTextsWrapper}>
              <p className={ArgumentTitle}>
                Profite des offres près de chez toi{" "}
                <span role="img" aria-label="position">
                  🎉
                </span>
              </p>
              <div className={PaddingTop20}></div>
              <p className={ArgumentDescription}>
                Grâce à l'onglet map de notre application, tu peux voir tous les
                commerces partenaires de WYNIT qui sont autour de toi. N'hésite
                pas à utiliser les filtres disponibles en haut de l'écran en
                fonction des catégories qui t'intéressent.
              </p>
            </div>
          </div>
        </div>
        <div className={ArgumentWrapper}>
          <div className={RowWrapper}>
            <div className={ArgumentTextsWrapper}>
              <p className={ArgumentTitle}>
                Wynit te propose tout ce dont tu as besoin !{" "}
                <span role="img" aria-label="offer">
                  🎉
                </span>
              </p>
              <div className={PaddingTop20}></div>
              <p className={ArgumentDescription}>
                Bowling, Footsalle, restaurant, salon de coiffure, institut de
                beauté, Lasergame, escape game, auto-école, hôtels... Et bien
                plus encore !
              </p>
            </div>
            <div className={MobileWrapper}></div>
            <div className={ImageWrapper}>
              <img
                alt="Mockup accueil"
                className={ImageContent}
                src={HomeMockup}
              />
            </div>
          </div>
        </div>
        <div className={ArgumentWrapper}>
          <div className={RowWrapper} style={{ flexWrap: "wrap-reverse" }}>
            <div className={ImageWrapper}>
              <img
                alt="Mockup profil"
                className={ImageContent}
                src={ProfileMockup}
              />
            </div>
            <div className={MobileWrapper}></div>
            <div className={ArgumentTextsWrapper}>
              <p className={ArgumentTitle}>
                Ton profil{" "}
                <span role="img" aria-label="offer">
                  🎉
                </span>
              </p>
              <div className={PaddingTop20}></div>
              <p className={ArgumentDescription}>
                Ton espace personnel te permet d'accéder à toutes tes
                informations et consulter les offres que tu as déjà utilisées.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Arguments
