import React from "react"
import {
  Wrapper,
  CarouselWrapper,
  DescriptionWrapper,
} from "./NosOffres.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import OfferCard from "./../../../components/OfferCard"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const NosOffres = ({ offers }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 770 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 769, min: 0 },
      items: 1,
    },
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Nos dernières offres</h1>
      <div className={DescriptionWrapper}>
        Découvre toutes nos offres sur ton application mobile ! Chaque jour, de
        nouvelles réductions et cadeaux sont ajoutés pour satisfaire toutes vos
        attentes ! Profite d'une offre de bienvenue et d'une offre permanente
        chez l'ensemble de nos partenaires. Avec Wynit, tu es déjà gagnant !
      </div>
      <div className={CarouselWrapper}>
        {offers !== null ? (
          <Carousel
            slidesToSlide={1}
            ssr={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            arrows={false}
            centerMode={false}
          >
            {offers &&
              offers.map((offer, idx = -1) => {
                idx++
                return (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}></div>
                    <OfferCard
                      image={offer["image"]}
                      date={""}
                      category={offer["category"]}
                      shopName={offer["shopId"]}
                      title={offer["title"]}
                    />
                    <div style={{ flex: 1 }}></div>
                  </div>
                )
              })}
          </Carousel>
        ) : (
          <p>Désolé, les offes ne sont pas disponibles pour l'instant.</p>
        )}
      </div>
    </div>
  )
}

export default NosOffres
