// extracted by mini-css-extract-plugin
export var FAQContent = "FAQ-module--FAQContent--p-qdg";
export var HelpContent = "FAQ-module--HelpContent--AU0gY";
export var HelpListWrapper = "FAQ-module--HelpListWrapper--3P-fI";
export var HelpWrapper = "FAQ-module--HelpWrapper--zwmBR";
export var HelpWrapperDeployed = "FAQ-module--HelpWrapperDeployed--DigCB";
export var OrangeText = "FAQ-module--OrangeText--NPAdN";
export var PlusWrapper = "FAQ-module--PlusWrapper--msl0f";
export var Spacer = "FAQ-module--Spacer--0FXr2";
export var TitleAndIconWrapper = "FAQ-module--TitleAndIconWrapper--mwuqf";
export var WhiteText = "FAQ-module--WhiteText--0kjQa";
export var Wrapper = "FAQ-module--Wrapper--OPGY8";