import React from "react"
import {
  Wrapper,
  DescriptionWrapper,
  PartnersNumberWrapper,
  CarouselWrapper,
  ImageWrapper,
  ImageContent,
  Spacer,
} from "./Partenaires.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

// Partenaires
import ATC from "./../../../res/partenaires/atc.png"
import AttrapReves from "./../../../res/partenaires/attrap_reves.png"
import Bioticinal from "./../../../res/partenaires/bioticinal.png"
import BubblesHome from "./../../../res/partenaires/bubbles_home.png"
import Care from "./../../../res/partenaires/care.png"
import CER from "./../../../res/partenaires/cer.png"
import ClimbUp from "./../../../res/partenaires/climb_up.png"
import CoriolisTelecom from "./../../../res/partenaires/coriolis_telecom.png"
import EasyMonneret from "./../../../res/partenaires/easy_monneret.png"
import EstheticCenter from "./../../../res/partenaires/esthetic_center.png"
import FoodPark from "./../../../res/partenaires/food_park.png"
import GameOver from "./../../../res/partenaires/game_over.png"
import Goolfy from "./../../../res/partenaires/goolfy.png"
import JMB from "./../../../res/partenaires/jmb.png"
import JoueClub from "./../../../res/partenaires/joue-club.png"
import KDC from "./../../../res/partenaires/kdc.png"

import KIP from "./../../../res/partenaires/kip.png"
import LaserGameEvolution from "./../../../res/partenaires/laser_game_evolution.png"
import Ligier from "./../../../res/partenaires/ligier.png"
import Marcels from "./../../../res/partenaires/marcels.png"
import MassiliaFive from "./../../../res/partenaires/massilia_five.png"
import MiSushi from "./../../../res/partenaires/mi_sushi.png"
import MicroCar from "./../../../res/partenaires/micro_car.png"
import MusikRoom from "./../../../res/partenaires/musik_room.png"
import MyoTec from "./../../../res/partenaires/myo_tec.png"
import NickiSurf from "./../../../res/partenaires/nicki_surf.png"
import PoutinesBrother from "./../../../res/partenaires/poutines_brother.png"
import QuizRoom from "./../../../res/partenaires/quiz_room.png"
import RMPhone from "./../../../res/partenaires/r&m_phone.png"
import Save from "./../../../res/partenaires/save.png"
import TennisPark from "./../../../res/partenaires/tennis_park.png"
import TheDarkDreams from "./../../../res/partenaires/the_dark_dreams.png"
import VirtualRoom from "./../../../res/partenaires/virtual_room.png"

const Partenaires = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Partenaires</h1>
      <p className={DescriptionWrapper}>
        Wynit, c'est plus de 50 établissements partenaires sur l'ensemble des
        Bouches du Rhône ! Nous avons tout ce dont tu as besoin : Restauration,
        bars, sports, loisirs, mode, bien-être, services et bien plus encore !
      </p>
      <div className={CarouselWrapper}>
        <p className={PartnersNumberWrapper}>
          + de 50 établissements partenaires nous font déjà confiance
        </p>
        <Carousel
          slidesToSlide={1}
          ssr={true}
          swipeable={true}
          draggable={true}
          responsive={responsive}
          infinite={true}
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={false}
          centerMode={false}
        >
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={ATC} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={AttrapReves}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={Bioticinal}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={BubblesHome}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={Care} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={CER} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={ClimbUp} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={CoriolisTelecom}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={EasyMonneret}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={EstheticCenter}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={FoodPark}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={GameOver}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={Goolfy} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={JMB} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={JoueClub}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={KDC} />
            <div className={Spacer}></div>
          </div>
        </Carousel>
        <Carousel
          slidesToSlide={1}
          ssr={true}
          swipeable={true}
          draggable={true}
          responsive={responsive}
          infinite={true}
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={false}
          centerMode={false}
        >
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={KIP} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={LaserGameEvolution}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={Ligier} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={Marcels} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={MassiliaFive}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={MiSushi} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={MicroCar}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={MusikRoom}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={MyoTec} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={NickiSurf}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={PoutinesBrother}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={QuizRoom}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={RMPhone} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img className={ImageContent} key="name" alt="name" src={Save} />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={TennisPark}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={TheDarkDreams}
            />
            <div className={Spacer}></div>
          </div>
          <div className={ImageWrapper}>
            <div className={Spacer}></div>
            <img
              className={ImageContent}
              key="name"
              alt="name"
              src={VirtualRoom}
            />
            <div className={Spacer}></div>
          </div>
        </Carousel>
      </div>
    </div>
  )
}

export default Partenaires
