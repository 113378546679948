import React from "react"
import { Wrapper } from "./Landing.module.css"

import LaunchScreen from "./LaunchScreen"
import SpecialOffer from "./SpecialOffer"
import Partenaires from "./Partenaires"
import NosOffres from "./NosOffres"
import Arguments from "./Arguments"
import FoireAuxQuestions from "./FAQ"
import NousContacter from "./NousContacter"

const Landing = ({ user, offers }) => {
  return (
    <div className={Wrapper}>
      <LaunchScreen />
      <SpecialOffer user={user} />
      <Partenaires />
      <NosOffres offers={offers} />
      <Arguments />
      <FoireAuxQuestions />
      <NousContacter />
    </div>
  )
}

export default Landing
