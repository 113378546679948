// extracted by mini-css-extract-plugin
export var AppleStoreMobile = "LaunchScreen-module--AppleStoreMobile--wVemw";
export var BetweenStoresButtons = "LaunchScreen-module--BetweenStoresButtons--1cPnl";
export var GooglePlayMobile = "LaunchScreen-module--GooglePlayMobile--PPoYn";
export var ImageContent = "LaunchScreen-module--ImageContent--dSYIB";
export var ImageWrapper = "LaunchScreen-module--ImageWrapper--jeQXF";
export var LaunchContent = "LaunchScreen-module--LaunchContent--LqKdL";
export var RowWrapper = "LaunchScreen-module--RowWrapper--z4fOM";
export var StoreImage = "LaunchScreen-module--StoreImage--nRfyF";
export var StoresWrapper = "LaunchScreen-module--StoresWrapper--aMUat";
export var SubtitleWrapper = "LaunchScreen-module--SubtitleWrapper--UhSp6";
export var TitleWrapper = "LaunchScreen-module--TitleWrapper--wIbYc";
export var Wrapper = "LaunchScreen-module--Wrapper--o-Dsf";