import React from "react"

import { Wrapper } from "./Button.module.css"

import { classNames } from "../../helpers/classNames"

const Button = ({ className, ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className={classNames([Wrapper, className])}
    />
  )
}

export default Button
