import React from "react"
import { Helmet } from "react-helmet"

export default function Thumbnail() {
  return (
    <Helmet
      title="Wynit | Tu es déjà gagnant"
      meta={[
        {
          property: "og:title",
          content: "Wynit",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content:
            "https://wynit.fr/static/Thumbnail_wynit_main-eae19fb31846ffa9f7d47c1303297ae5.png",
        },
        {
          property: "og:description",
          content:
            "WYNIT, les bons plans marseillais pour les 15-30 ans. Sur cette page, tu trouveras toutes les informations à propos de WYNIT.",
        },
      ]}
    />
  )
}
